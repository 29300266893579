<template>

    

    <div style="padding:20px;">
        
        <v-card class="" elevation="" max-width="" >
            <v-card-text>

               <div class="linhaFormulario">

                    <div class="celularCampoForm">
                        <v-select color v-model="emAndamento" :items="listaStatusAndamento" label="Iniciadas"  item-text="nome" return-object item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="concluido" :items="listaStatusConcluido" label="Concluidas"  item-text="nome" return-object item-value="id"></v-select>
                    </div>
                    <div class="celularCampoForm">
                        
                    </div>

                    <div class="celularCampoForm">
                            <v-btn block elevation="" large color="info" @click="listaProjetos()" >Buscar</v-btn>
                    </div>

                </div>
                
            </v-card-text>
               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="projetos"
                            :items-per-page="2300"
                            class="elevation-1"
                        >

                            <template v-slot:[`item.percentualconcluida`]="{ item }">
                                <div style="display:flex;flex-direction:row;width:200px;">
                                    <div style="width:150px;">
                                        <a @click="verAtividadesDoProjeto(item)" >
                                            <progress-bar :options="options" :value="item.percentualconcluida"  />
                                        </a>
                                    </div>
                                    <div style="width:50px;display:flex;align-items:flex-end;padding-bottom:10px;">
                                        <a @click="verMovimentosDoProjeto(item)" >Mov.</a>
                                    </div>
                                </div>
                            </template> 

                            
                            
                        
                        </v-data-table>
                    </template>


            </v-card-text>
               
        </v-card>



                <v-dialog v-model="exibirModalMovimentacoes" width="80vw" height="80vh" max-width="80vw" min-width="80vh" >

                            <v-container class="pa-0" style="width:100%;height:100%;background-color:white;">
                                 
                                <v-row  class="pa-0" style="width:100%;height:100%;display:flex;flex-direction:row;">
                                    
                                    <v-col cols="12" md="5">
                                        <div style="padding:10px;">
                                            <v-card  width="100%" style="margin-bottom:10px;" >
                                                <v-card-title primary-title>Adicionar Nova Movimentação</v-card-title>
                                                <v-card-text>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-textarea height="50" outlined hide-details label="Movimentação" v-model="descricao" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                        </v-col>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                                <div style="width:100%;display:flex;flex-direction:columns;align-items:flex-end;justify-content:flex-end;margin-top:10px;">
                                                                    <v-btn small elevation="" color="info" @click="salvarNovaMovimentacao()" >Salvar</v-btn>
                                                                </div>
                                                        </v-col>
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="7" syle="">
                                        <div style="max-height:70vh;overflow:auto;background-color:#dadada;height:100%;padding:10px;">
                                            <v-card v-for="mov in listaMovimentos" :key="mov.datahoramovimento" width="100%" style="margin-bottom:10px;" >
                                                <v-card-title primary-title>{{mov.desctipomovimento}} </v-card-title>
                                                <v-card-text>{{mov.descricao}}</v-card-text>
                                                <v-card-text>
                                                    <div style="display:flex;flex-direction:column;align-items:flex-end;" >{{mov.datamov}} as {{mov.horamov}}</div>
                                                    <div style="display:flex;flex-direction:column;align-items:flex-end;" >{{mov.nomeusuario}}</div>
                                                </v-card-text> 
                                            </v-card>
                                        </div>

                                    </v-col>    

                                </v-row>

                            </v-container>



                </v-dialog>


    </div>




    

    
</template>

<script>


// import sistemas from '@/services/sistema.service.js'
// import implantacao from '@/services/implantacao.service.js'
import projetos from '@/services/prioridades.service.js'


// import dayjs from 'dayjs'

export default {
    name:'Projetos',
    components: {
        
    },
    data(){
        return{
            descricao:null,
            projetoSelecionado:null,
            exibirModalMovimentacoes:false,
            listaMovimentos:[],
            emAndamento:{id:1,nome:'Todos'},
            listaStatusAndamento:[
                {id:1,nome:'Todos'},
                {id:2,nome:'Não Iniciado'},
                {id:3,nome:'Iniciado'},
            ],
            concluido:{id:1,nome:'Todos'},
            listaStatusConcluido:[
                {id:1,nome:'Todos'},
                {id:2,nome:'Não Concluído'},
                {id:3,nome:'Concluído'},
            ],
            headers: [
                        {
                            text: 'ID',
                            align: 'start',
                            sortable: false,
                            value: 'id',
                            width:'100px'
                        },
                        { text: 'Nome', value: 'titulo' ,width:'300px'},
                        { text: '% Concluida', value: 'percentualconcluida',width:'200px'},
                        { text: 'Historico', value: 'titulo',width:'200px'},
                        { text: 'Total', value: 'total' },
                        { text: 'Total Concluida', value: 'totalconcluida' },
                    ],
            projetos:[],
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: true,
                    shadowColor: '#000000',
                    fontSize: 14,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#2dbd2d',
                    backgroundColor: '#333333',
                    inverted: false
                },
                layout: {
                    height: 35,
                    width: 140,
                    verticalTextAlign: 61,
                    horizontalTextAlign: 43,
                    zeroOffset: 0,
                    strokeWidth: 30,
                    progressPadding: 0,
                    type: 'line'
                }
                }
        }
    },
    watch: {
        
    },
    methods:{
            salvarNovaMovimentacao(){

                let objeto = {
                    idprojeto:this.projetoSelecionado.id,
                    descricao:this.descricao,
                    idusuario:localStorage.getItem('idusuario'),
                    tipomovimento:3,
                    idcliente:this.projetoSelecionado.idcliente
                }

                // alert(JSON.stringify(this.projetoSelecionado,null,2))

                projetos.salvarNovaMovimentacao(objeto).then(res => res.data)
                    .then(async () =>{
                            this.carregarMovimentosDoProjeto(this.projetoSelecionado)
                            this.projetoSelecionado = null 
                            this.descricao = null
                    })
                    .catch(error =>{
                        alert(error)
                    })

            },
            verAtividadesDoProjeto(projeto){
                window.open('/Projetos/Atividades/'+projeto.id, '_blank');
               
            },  
            async carregarMovimentosDoProjeto(projeto){
                this.listaMovimentos  =  await projetos.buscarMovimentoProjetos({idprojeto:projeto.id}).then(res => res.data)
            },
            async verMovimentosDoProjeto(projeto){
                
                this.projetoSelecionado = projeto

                this.exibirModalMovimentacoes = !this.exibirModalMovimentacoes

                if(this.exibirModalMovimentacoes){
                   this.carregarMovimentosDoProjeto(projeto)
                }
               
            },  
            async listaProjetos(){
                let parametros = {
                    emAndamento:this.emAndamento.id,
                    concluido:this.concluido.id,
                }
                this.projetos = await projetos.buscarProjetos(parametros).then(res => res.data)
            }
            
    },

    
    async beforeMount(){
        await this.listaProjetos()
        document.title = 'PROJETOS';

    }
    
}
</script>

<style>

    .linhaFormulario{
        display: flex;
        flex-direction: row;
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
    }

</style>